import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import WhyUs from "../components/WhyUs";
import About from "../components/About";
import ServicesContent from "../components/ServicesContent";
import CareerContent from "../components/CareerContent";
import Privacy from "../components/Privacy";
import Terms from "../components/Terms";
import Refund from "../components/Refund";
import Error from "../components/Error";
import { Loader } from "../components/Loader";
import Layout from "../context/Layout";
import InnerHeader from "../components/InnerHeader";
import Offcanvas from "../components/Offcanvas";
import Cloud from "../components/Cloud";
// import PortfolioNew from "../components/PortfolioNew";
import Portfolionew2 from "../components/Portfolionew2";
import Blog from "../components/Blog";
import BlogDetail from "../components/BlogDetail";

// import Home from '../components/Home'
// import Career from '../components/Career'
// import Services from '../components/Services'
// import Header from '../components/Header'
// import Footer from '../components/Footer'
// import Header from '../components/Header'
// import Footer from '../components/Footer'
// import AboutUs from '../components/AboutUs'
// import ReachUsContent from '../components/ReachUsContent'
// import Header from '../components/Header'
// import Portfolio from '../components/Portfolio'

const Home = lazy(() => import("../pages/Home"));
const AboutUs = lazy(() => import("../components/AboutUs"));
const Services = lazy(() => import("../components/Services"));
// const Portfolio = lazy(() => import("../components/PortfolioNew"));
const PortfolioNew = lazy(() => import("../components/PortfolioNew"));

const Career = lazy(() => import("../components/Career"));
const ReachUsContent = lazy(() => import("../components/ReachUsContent"));

const Allroutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      {/* <Header /> */}
      <Routes>
        <Route
          path="/"
          index
          exact
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout>
              <AboutUs />
            </Layout>
          }
        />
        <Route
          path="/services"
          element={
            <Layout>
              <Services />
            </Layout>
          }
        />
        <Route
          path="/career"
          element={
            <Layout>
              <Career />
            </Layout>
          }
        />
        <Route
          path="/whyus"
          element={
            <Layout>
              <WhyUs />
            </Layout>
          }
        />
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="about-content"
          element={
            <Layout>
              <About />
            </Layout>
          }
        />
        <Route
          path="reach-us-contact"
          element={
            <Layout>
              <ReachUsContent />
            </Layout>
          }
        />
        <Route
          path="/services-contact"
          element={
            <Layout>
              <ServicesContent />
            </Layout>
          }
        />
        {/* <Route path="/portfolio" element={<Layout><Portfolio /></Layout>} /> */}
        <Route
          path="/portfolio"
          element={
            <Layout>
              <PortfolioNew />
            </Layout>
          }
        />

        <Route path="/blog" element={<Layout><Blog /></Layout>} />

        <Route
          path="/career-content"
          element={
            <Layout>
              <CareerContent />
            </Layout>
          }
        />
        <Route
          path="/privacy"
          element={
            <Layout>
              <Privacy />
            </Layout>
          }
        />
        <Route
          path="/term"
          element={
            <Layout>
              <Terms />
            </Layout>
          }
        />
        <Route
          path="/refund"
          element={
            <Layout>
              <Refund />
            </Layout>
          }
        />
        {/* <Route path="*" element={<Error />} /> */}
        <Route path="inner-header" element={<InnerHeader />} />
        <Route path="canvas" element={<Offcanvas />} />
        <Route
          path="cloud"
          element={
            <Layout>
              <Cloud />
            </Layout>
          }
        />
        <Route
          path="/blogdetail/:id"
          element={
            <Layout>
              <BlogDetail />
            </Layout>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default Allroutes;
