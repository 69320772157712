import React, { useEffect, useState } from "react";
import InnerHeader from "./InnerHeader";
import "../assets/style/Blog.css";
import { Link } from "react-router-dom";
import { BlogList } from "./Api";
import { FaClock, FaSearch, FaUser } from "react-icons/fa";


const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const Blog = () => {
    const [error, setError] = useState(null);

    const [blog, setBlog] = useState([]);
    useEffect(() => {
        const BlogListFetch = async () => {
            try {
                const response = await BlogList();
                setBlog(response.data);
                console.log(response);
            } catch (error) {
                setError(error);
            }
        };
        BlogListFetch();
    }, []);

    return (
        <>
            <InnerHeader />
            <section className="blog-banner-section d-flex align-items-center">
                <div className="blog-overlay"></div>
                <div className="container text-center banner-content">
                    <h2 className="blog-banner-title"> Blogs</h2>
                </div>
            </section>

            <div className="container content-wrap">
                <main className="main-posts-list ">
                    <div className="col-lg-8 col-md-12 col-sm-12 mb-4">
                        {blog.map((item, index) => (
                            <div className="" key={index}>
                                <article className="article d-flex">
                                    <div className="article-img-column">
                                        <Link
                                            to={`/blogdetail/${item.id}`}
                                        >
                                            <img
                                                className="article-img img-fluid"
                                                src={`${process.env.REACT_APP_BASE_URL}/${item.photo}`}
                                                alt={item.title}
                                            />
                                        </Link>
                                    </div>
                                    <div className="article-text-column">
                                        <ul className="tab-list list-unstyled d-flex flex-wrap">
                                            <li className="tab-item me-3">
                                                <a className="tab-link" href="#0">
                                                    {item.category.category_name}
                                                </a>
                                            </li>
                                        </ul>
                                        <h2 className="article-title">
                                            <Link
                                                to={`/blogdetail/${item.id}`}
                                                state={{
                                                    title: item.title,
                                                    author_name: item.author_name,
                                                    date: item.date,
                                                    image: `${REACT_APP_BASE_URL}/${item.photo}`,
                                                    detailPhoto: `${REACT_APP_BASE_URL}/${item.detailphoto}`,
                                                    description: item.description,
                                                    keywords: item.keywords
                                                }}
                                                className="textdecoration-none text-black"
                                            >
                                                {item.title}
                                            </Link>
                                        </h2>

                                        <div className="auth">
                                            <time className="article-datetime" dateTime={item.date}>
                                                <FaClock className="mr-2" />
                                                {item.date}
                                            </time>
                                            <cite className="article-author">
                                                <FaUser className="mr-2" />
                                                {item.author_name}
                                            </cite>
                                        </div>
                                        <div className="article-content">
                                            <p
                                                className="clamped-text mb-0"
                                                dangerouslySetInnerHTML={{ __html: item.description }}
                                            ></p>
                                            <ul className="tab-list list-unstyled d-flex flex-wrap">
                                                <li className="tab-item me-3">

                                                    <Link
                                                        to={`/blogdetail/${item.id}`}
                                                        state={{
                                                            title: item.title,
                                                            author_name: item.author_name,
                                                            date: item.date,
                                                            image: `${REACT_APP_BASE_URL}/${item.photo}`,
                                                            detailPhoto: `${REACT_APP_BASE_URL}/${item.detailphoto}`,
                                                            description: item.description,
                                                            keywords: item.keywords
                                                        }}
                                                    >
                                                        Read more...
                                                    </Link>
                                                    {/* <a className="tab-link" href="#0">
                            {item.category.category_name}
                          </a> */}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        ))}
                    </div>

                    <aside className="sidebar col-lg-4 col-md-8 col-sm-12">
                        <div className="search-box">
                            <input type="search" placeholder="Search Blog" />
                            <div className="search-icon">
                                <FaSearch />
                            </div>
                        </div>

                        <section className="new-posts mb-4">
                            <h2 className="sidebar-title">Recent Blogs</h2>
                            <div className="new-posts-list">
                                {blog
                                    .filter((item) => item.trending === 1)
                                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                                    .map((item, index) => (
                                        <article
                                            className="new-posts-article d-flex mb-3"
                                            key={index}
                                        >
                                            <div className="post-img-column">
                                                <Link
                                                    to={`/blogdetail/${item.id}`}
                                                    state={{
                                                        title: item.title,
                                                        author_name: item.author_name,
                                                        date: item.date,
                                                        image: `${REACT_APP_BASE_URL}/${item.photo}`,
                                                        detailPhoto: `${REACT_APP_BASE_URL}/${item.detailphoto}`,
                                                        description: item.description,
                                                        keywords: item.keywords
                                                    }}
                                                >
                                                    <img
                                                        className="new-posts-image img-fluid"
                                                        src={`${process.env.REACT_APP_BASE_URL}/${item.photo}`}
                                                        alt={item.imgAlt}
                                                        style={{
                                                            borderRadius: "10px",
                                                            height: "auto",
                                                            maxWidth: "80px",
                                                        }}
                                                    />
                                                </Link>
                                            </div>
                                            <div className="post-text-column ms-3">
                                                <h5 className="new-posts-title">
                                                    <Link
                                                        to={`/blogdetail/${item.id}`}
                                                        state={{
                                                            title: item.title,
                                                            author_name: item.author_name,
                                                            date: item.date,
                                                            image: `${process.env.REACT_APP_BASE_URL}/${item.photo}`,
                                                            detailPhoto: `${process.env.REACT_APP_BASE_URL}/${item.detailphoto}`,
                                                            description: item.description,
                                                        }}
                                                        className="new-posts-link"
                                                    >
                                                        {item.title}
                                                    </Link>
                                                </h5>

                                                <div className="auth">
                                                    <time
                                                        className="article-datetime"
                                                        dateTime={item.date}
                                                    >
                                                        <FaClock className="mr-2" />
                                                        {item.date}
                                                    </time>
                                                    <cite className="article-author">
                                                        <FaUser className="mr-2" />
                                                        {item.author_name}
                                                    </cite>
                                                </div>
                                            </div>
                                        </article>
                                    ))}
                            </div>
                        </section>

                        <section className="tags mb-4">
                            <h2 className="sidebar-title">Trending</h2>
                            <ul className="tags-list list-unstyled d-flex flex-wrap gap-2">
                                {blog
                                    .filter((item) => item.trending === 1)
                                    .map((item, index) => (
                                        <li className="tag-item me-2" key={index}>
                                            <a className="tag-link" href={`/blogdetail/${item.id}`}>
                                                {item.title}
                                            </a>
                                        </li>
                                    ))}
                            </ul>
                        </section>

                        <section className="categories">
                            <h2 className="sidebar-title">Categories</h2>
                            <ul className="categories-list list-unstyled">
                                {Array.from(
                                    new Set(blog.map((item) => item.category.category_name))
                                ).map((category, index) => (
                                    <li
                                        className="category-item border-bottom pb-2 mb-2"
                                        key={index}
                                    >
                                        <a className="category-link" href="#0">
                                            {category}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </section>
                    </aside>
                </main>
            </div>
        </>
    );
};

export default Blog;
